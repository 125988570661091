import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Tooltip,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/white.png';
 
const drawerWidth = 240;
const collapsedWidth = 60;
 
const FieldEngineerDrawer = ({ open, handleDrawerToggle, onMenuClick }) => {
  const navigate = useNavigate();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
 
  const handleMenuClick = (menuItem) => {
    switch (menuItem) {
      case 'Dashboard':
        navigate('/dashboard');
        break;
      case 'WorkOrderAssignment':
        navigate('/work-order-assignment');
        break;
      case 'AssignOrderList':
        navigate('/assign-order-list');
        break;
      case 'Logout':
        setLogoutDialogOpen(true); // Open logout confirmation dialog
        break;
      default:
        break;
    }
  };
 
  const confirmLogout = () => {
    setLoggingOut(true); // Show spinner
    setTimeout(() => {
      // Clear session data here if needed
      navigate('/login'); // Navigate to login page
    }, 1000); // Delay for smooth logout transition
  };
 
  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };
 
  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerToggle}
        variant="permanent"
        sx={{
          width: open ? drawerWidth : collapsedWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : collapsedWidth,
            boxSizing: 'border-box',
            background: '#BC3232',
            color: '#fff',
            transition: 'width 0.3s ease',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {/* Drawer Close/Open Icon */}
        <Box sx={{ display: 'flex', justifyContent: open ? 'flex-end' : 'center', alignItems: 'center', padding: '8px' }}>
          {open && (
            <IconButton onClick={handleDrawerToggle} sx={{ color: '#fff' }}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
 
        {/* Drawer Logo */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: open ? 'flex-start' : 'center',
            alignItems: 'center',
            padding: '16px',
            cursor: 'pointer',
          }}
          onClick={handleDrawerToggle}
        >
          <img
            src={logo}
            alt="Logo"
            style={{
              width: open ? '200px' : '30px',
              transition: 'width 0.3s ease',
            }}
          />
        </Box>
 
        {/* Drawer List Items */}
        <List sx={{ flexGrow: 1 }}>
          <Tooltip title="Dashboard" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Dashboard')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <DashboardIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Dashboard" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>
 
     
 
          <Tooltip title="Assign Order List" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('AssignOrderList')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <AssignmentIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Assign Order List" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>
        </List>
 
        {/* Spacer to push logout button to the bottom */}
        <Box sx={{ flexGrow: 1 }} />
 
        <Box sx={{ padding: '4px' }}>
          <Button color="inherit" startIcon={<LogoutIcon />} onClick={() => handleMenuClick('Logout')} sx={{ mb:'3', height:40}}>
            {open && 'Logout'}
          </Button>
        </Box>
      </Drawer>
 
      {/* Logout Confirmation Dialog */}
      <Dialog open={logoutDialogOpen} onClose={closeLogoutDialog}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent sx={{mb:'3', mt:0 }}>
          <DialogContentText>Are you sure you want to log out?</DialogContentText>
        </DialogContent>
        <DialogActions><Button onClick={closeLogoutDialog} color="secondary">Cancel</Button>
          <Button onClick={confirmLogout} color="primary" autoFocus>Logout
        </Button>
        </DialogActions>
      </Dialog>
 
      {/* Logout Spinner */}
      {loggingOut && (
        <Box sx={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1301,}}>
          <CircularProgress size={50} sx={{ color: '#fff' }} />
        </Box>
      )}
    </>
  );
};
 
export default FieldEngineerDrawer;
 
 