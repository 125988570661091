import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import AdminDashboard from './pages/AdminDashboard';
import FieldEngineerDashboard from './pages/FieldEngineerDashboard';
import CardDetailsPage from './components/CardDetailsPage';
import FSEUsers from './components/FSEUsers';
import ECTDetails from './pages/ECTDetails';  // Import the ECT details page
import T3BDetails from './pages/T3BDetails';
import AssignFieldEngineer from './pages/AssignFieldEngineer'
import AssignTaskOrderTable from './pages/AssignTaskOrderTable';
import ResourceUtilization from './pages/ResourceUtilization'; // Import the new page
import TicketManagement from './pages/TicketManagement';
import SlaManagement from './pages/SlaManagement'; // Adjust the path as per your project structure
import EscalationPage from './pages/EscalationPage'; // Update the path if necessary
import RRRPage from './pages/RRRPage'; // Adjust the import path



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ChangePassword />} />
        <Route path="/field-engineer" element={<FieldEngineerDashboard />} />
        <Route path="/card-details/:filterKey" element={<CardDetailsPage />} />
        <Route path="/ectdetail" element={<ECTDetails />} />  {/* Add this route */}
        <Route path="/t3b-details" element={<T3BDetails />} />
        <Route path="/assign-field-engineer" element={<AssignFieldEngineer />} />
        <Route exact path="/fseusers" element={<FSEUsers />} />        
        <Route path="/assign-task-order-table" element={<AssignTaskOrderTable />} />
        <Route path="/resource-utilization" element={<ResourceUtilization />} />
        <Route path="/tickets" element={<TicketManagement />} />
        <Route path="/sla-management" element={<SlaManagement />} />
        <Route path="/escalation" element={<EscalationPage />} /> {/* Escalation Page */}
        <Route path="/rrr-page" element={<RRRPage />} />







      </Routes>
    </Router>
  );
};

export default App;
