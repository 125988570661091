import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, InputBase, Button, Avatar, Badge } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import logo from '../assets/logo1.png'; // Replace with your logo path

const Header = ({ handleDrawerToggle, title }) => {
  const [loggedInUser, setLoggedInUser] = useState({ username: '', role: '' });

  // Retrieve user data from localStorage
  useEffect(() => {
    const userData = localStorage.getItem('loggedInUser');
    if (userData) {
      setLoggedInUser(JSON.parse(userData));
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{ background: 'white', zIndex: (theme) => theme.zIndex.drawer + 1, color: '#333', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Left Section: Logo - Clickable */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}  // Clicking logo toggles the drawer
            sx={{ mr: 2 }}
          >
            <img src={logo} alt="Logo" style={{ width: '40px', height: '40px' }} />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
            My Dashboard
          </Typography>
        </Box>

    

    {/* Right Section: Icons and User Profile */}
<Box sx={{ display: 'flex', alignItems: 'center' }}>
  <Badge badgeContent={1} color="error">
    <NotificationsIcon />
  </Badge>
  {/* Display logged-in user info with avatar */}
  {loggedInUser .username && (
    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
      {/* Avatar icon */}
      <Avatar sx={{ width: 30, height: 30, mr: 1 }} />
      <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {loggedInUser .username}
        </Typography>
        <Typography variant="body2" sx={{ color: '#666' }}>
          {loggedInUser .role} {/* Display the role on a new line */}
        </Typography>
      </Box>
    </Box>
  )}
</Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
