import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Collapse,
  IconButton,
  Divider,
  TablePagination,
  Drawer,
  Card,
  Select,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LoopIcon from '@mui/icons-material/Loop';
import moment from 'moment';
import { API_BASE_URL } from '../services/apiService';

// Styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme, isExpired, isFixed }) => ({
  backgroundColor: isExpired ? theme.palette.error.light : isFixed ? theme.palette.success.light : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const AssignFieldEngineer = () => {
  const [fieldEngineers, setFieldEngineers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openTickets, setOpenTickets] = useState([]);
  const [openTicketsVisible, setOpenTicketsVisible] = useState(false);
  const [openTicketsPage, setOpenTicketsPage] = useState(0);
  const [openTicketsRowsPerPage, setOpenTicketsRowsPerPage] = useState(5);

  const fetchFieldEngineers = async () => {
    try {
      setLoading(true);
      const [engineersRes, tasksRes, openTicketsRes] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/field-engineers`),
        axios.get(`${API_BASE_URL}/api/tickets`),
        axios.get(`${API_BASE_URL}/api/open-tickets`),
      ]);

      const engineers = engineersRes.data || [];
      const tasks = tasksRes.data || [];

      const processedEngineers = engineers.map((engineer) => {
        const assignedTickets = tasks.filter((task) => task.assignedEngineerId === engineer._id);
        const ticketCount = assignedTickets.length;

        const StatusStatuses = assignedTickets.map((ticket) => ({
          woNumber: ticket['WO#'],
          Status: ticket['Status'],
          msn: ticket['MSN#'],
          assignDate: ticket['Assign Ticket Date'],
          proficiency: ticket.Proficiency,
          _id: ticket._id,
          isExpired: new Date() > new Date(ticket['Completion Deadline']) && ticket['Status'] !== 'Fixed',
          isFixed: ticket['Status'] === 'Fixed',
        }));

        return { ...engineer, ticketCount, StatusStatuses };
      });

      setFieldEngineers(processedEngineers);

      // Filter open tickets where no Tech Assignment is present
      const filteredOpenTickets = openTicketsRes.data.filter((ticket) => !ticket['Tech Assignment']);
      setOpenTickets(filteredOpenTickets);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFieldEngineers();
  }, []);

  const handleToggleOpenTickets = () => {
    setOpenTicketsVisible(!openTicketsVisible);
  };

  const handleReassign = async (ticketId, newEngineerId) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/api/tickets/reassign/${ticketId}`, { newEngineerId });
      if (response.data.message) {
        alert('Ticket successfully reassigned.');
        fetchFieldEngineers();
      }
    } catch (error) {
      console.error('Error reassigning ticket:', error);
      alert('Failed to reassign ticket.');
    }
  };

  const toggleRowExpansion = (engineerId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [engineerId]: !prev[engineerId],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenTicketsPageChange = (event, newPage) => {
    setOpenTicketsPage(newPage);
  };

  const handleOpenTicketsRowsPerPageChange = (event) => {
    setOpenTicketsRowsPerPage(parseInt(event.target.value, 10));
    setOpenTicketsPage(0);
  };

  const displayedEngineers = fieldEngineers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const displayedOpenTickets = openTickets.slice(
    openTicketsPage * openTicketsRowsPerPage,
    openTicketsPage * openTicketsRowsPerPage + openTicketsRowsPerPage
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      
  
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 10, mb: 10 }}>
        <Header handleDrawerToggle={() => setDrawerOpen(!drawerOpen)} />

        {/* Open Tickets Toggle Card */}
        <Card
          onClick={handleToggleOpenTickets}
          sx={{
            cursor: 'pointer',
            backgroundColor: 'secondary.main',
            color: 'white',
            p: 2,
            mb: 2,
            borderRadius: '8px',
            textAlign: 'center',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
          }}
        >
          <Typography variant="h6">Open Tickets</Typography>
          <Typography variant="body2">
            {openTicketsVisible ? 'Click to hide open tickets' : 'Click to view open tickets'}
          </Typography>
        </Card>

        {/* Open Tickets Table */}
        {openTicketsVisible && (
          <StyledTableContainer component={Paper} elevation={3} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>WO#</StyledTableCell>
                  <StyledTableCell>MSN#</StyledTableCell>
                  <StyledTableCell>Customer Postal Code</StyledTableCell>
                  <StyledTableCell>Proficiency</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Assigned Date/Time</StyledTableCell>
                  <StyledTableCell>Reassign</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedOpenTickets.map((ticket) => (
                  <StyledTableRow key={ticket._id}>
                    <TableCell>{ticket['WO#'] || 'N/A'}</TableCell>
                    <TableCell>{ticket['MSN#'] || 'N/A'}</TableCell>
                    <TableCell>{ticket['Customer Postal Code'] || 'N/A'}</TableCell>
                    <TableCell>{ticket.Proficiency || 'N/A'}</TableCell>
                    <TableCell>{ticket.City || 'N/A'}</TableCell>
                    <TableCell>
                      {ticket['Assign Ticket Date']
                        ? moment(ticket['Assign Ticket Date'], 'D/M/YYYY, h:mm:ss a').format('DD/MM/YYYY, hh:mm A')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Select
                        displayEmpty
                        defaultValue=""
                        onChange={(e) => handleReassign(ticket._id, e.target.value)}
                        sx={{ minWidth: 150 }}
                      >
                        <MenuItem value="" disabled>
                          Select Engineer
                        </MenuItem>
                        {fieldEngineers
                          .filter(
                            (engineer) =>
                              engineer.city === ticket.City &&
                              engineer.postalCode === ticket['Customer Postal Code'] &&
                              engineer.proficiency === ticket.Proficiency
                          )
                          .map((engineer) => (
                            <MenuItem key={engineer._id} value={engineer._id}>
                              {engineer.username}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={openTickets.length}
              rowsPerPage={openTicketsRowsPerPage}
              page={openTicketsPage}
              onPageChange={handleOpenTicketsPageChange}
              onRowsPerPageChange={handleOpenTicketsRowsPerPageChange}
            />
          </StyledTableContainer>
        )}

        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333', textAlign: 'center' }}>
          Field Engineers and Assigned Tickets
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <StyledTableContainer component={Paper} elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Contact Number</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Postal Code</StyledTableCell>
                  <StyledTableCell>Proficiency</StyledTableCell>
                  <StyledTableCell>Assigned Tickets</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedEngineers.map((engineer) => (
                  <React.Fragment key={engineer._id}>
                    <StyledTableRow>
                      <TableCell>{engineer.username}</TableCell>
                      <TableCell>{engineer.email}</TableCell>
                      <TableCell>{engineer.contactNumber}</TableCell>
                      <TableCell>{engineer.city}</TableCell>
                      <TableCell>{engineer.postalCode}</TableCell>
                      <TableCell>{engineer.proficiency}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleRowExpansion(engineer._id)}>
                          {expandedRows[engineer._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        {engineer.ticketCount}
                      </TableCell>
                      <TableCell>
                        <Typography>Recommended by Lenovo</Typography>
                      </TableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={expandedRows[engineer._id]} timeout="auto" unmountOnExit>
                          <Box margin={2} sx={{ backgroundColor: '#f9f9f9', borderRadius: '8px', p: 2 }}>
                            <Typography variant="h6" color="primary">
                              Status Status
                            </Typography>
                            <Divider sx={{ my: 1 }} />
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>WO#</StyledTableCell>
                                  <StyledTableCell>Status</StyledTableCell>
                                  <StyledTableCell>MSN</StyledTableCell>
                                  <StyledTableCell>Assigned Date/Time</StyledTableCell>
                                  <StyledTableCell>Proficiency</StyledTableCell>
                                  <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {engineer.StatusStatuses.map((Status, index) => (
                                  <StyledTableRow key={index} isExpired={Status.isExpired} isFixed={Status.isFixed}>
                                    <TableCell>{Status.woNumber}</TableCell>
                                    <TableCell>{Status.Status}</TableCell>
                                    <TableCell>{Status.msn}</TableCell>
                                    <TableCell>
                                      {Status.assignDate
                                        ? moment(Status.assignDate, 'D/M/YYYY, h:mm:ss a').format('DD/MM/YYYY, hh:mm A')
                                        : 'Date not available'}
                                    </TableCell>
                                    <TableCell>{Status.proficiency}</TableCell>
                                    <TableCell>
                                      <Tooltip title="Reassign Ticket">
                                        <IconButton
                                          color="primary"
                                          onClick={() => handleReassign(Status._id, null)} // Pass null initially for reassignment
                                          sx={{
                                            mt: 1,
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                            '&:hover': {
                                              backgroundColor: 'secondary.main',
                                              transform: 'rotate(360deg)',
                                              transition: 'transform 0.5s ease-in-out',
                                            },
                                            boxShadow: 3,
                                            borderRadius: '50%',
                                            p: 1,
                                          }}
                                        >
                                          <LoopIcon fontSize="medium" />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={fieldEngineers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledTableContainer>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default AssignFieldEngineer;
