import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons

const AssignOrderList = ({ onAcknowledgeTask }) => {
  const [tasks, setTasks] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState('');




  useEffect(() => {
    // Retrieve the logged-in user from local storage
    const user = JSON.parse(localStorage.getItem('loggedInUser'));
    if (user) {
      setLoggedInUser(user.username);
    }
  }, []);

  useEffect(() => {
    // Fetch tasks for the specific Tech Assignment based on logged-in user
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`); // Adjust the endpoint as needed

        // Filter tasks based on logged-in user
        const filteredTasks = response.data.filter(task =>
          task["Tech Assignment"] && task["Tech Assignment"].trim() === loggedInUser
        );

        setTasks(filteredTasks);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    // Fetch tasks only if loggedInUser is defined
    if (loggedInUser) {
      fetchTasks();
    }
  }, [loggedInUser]);

  const handleAcknowledgeTask = (task) => {
    // Pass the acknowledged task to the parent component (WorkOrderAssignment)
    onAcknowledgeTask(task);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">
          Assigned Orders for {loggedInUser || 'User'}
        </Typography>
        <Typography variant="h6">
          Total Tickets: {tasks.length}
        </Typography>
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Logged in as: {loggedInUser}
      </Typography>

      {tasks.length > 0 ? (
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Case Number</TableCell>
                <TableCell>WO Number</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Customer Mobile Number</TableCell>
                <TableCell>Customer Address</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Time Stamp</TableCell>
                <TableCell>Pause Reason</TableCell> {/* Add Pause Reason column */}
                <TableCell>ECT</TableCell> {/* Add Pause Reason column */}

                <TableCell>Acknowledge</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, index) => (
                <TableRow key={index} style={{ cursor: 'pointer' }}>
                  <TableCell>{task["Case#"]}</TableCell>
                  <TableCell>{task["WO#"]}</TableCell> 
                  <TableCell>{task["Customer Name"]}</TableCell>
                  <TableCell>{task["Contact Number"]}</TableCell>
                  <TableCell>{task["Customer Address"]}</TableCell>
                  <TableCell>{task["Status"]}</TableCell>
                  <TableCell>{task["Time Stamp"]}</TableCell>
                  <TableCell>{task["Pause Reason"] || 'N/A'}</TableCell> {/* Show Pause Reason */}
                  <TableCell>{task["ECT"]}</TableCell> {/* Show Pause Reason */}

                  <TableCell>
                    <IconButton color="primary" onClick={() => handleAcknowledgeTask(task)}>
                      <CheckCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No tasks found for {loggedInUser || 'User'}.</Typography>
      )}
    </Box>
  );
};

export default AssignOrderList;
