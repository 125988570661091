import React, { useState } from 'react';
import axios from 'axios';
import { Box, Paper, Typography, TextField, Button, Link, Snackbar, MenuItem } from '@mui/material';
import { API_BASE_URL } from '../services/apiService';

const ForgotPassword = () => {
    const [formData, setFormData] = useState({ email: '' });
    const [error, setError] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  
    const handleInput = (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = async () => {
      if (!formData.email) {
          setError('Email is required');
          return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
          setError('Please enter a valid email address');
          return;
      }

      setError(''); 
      try {
          const res = await axios.post(`${API_BASE_URL}/api/request-password-reset`, { 
              email: formData.email, 
          });

          if (res.data.success) {
              setSnackbarOpen(true); 
              setError('');
          } else {
              setError(res.data.msg || 'An error occurred');
          }
      } catch (error) {
          setError('Failed to send reset link.');
      }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', bgcolor: '#f0f2f5' }}>
      <Paper elevation={6} sx={{ padding: '40px', borderRadius: '15px', width: '100%', maxWidth: '500px', bgcolor: '#ffffff' }}>
        <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', color: 'black', fontWeight: '400' }}>Forgot Password</Typography>


        {error && <Typography color="error" sx={{ textAlign: 'center', mb: 3, fontWeight: '300' }}>{error}</Typography>}

        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', fontSize: '14px', color:'black' }}>
          Enter your email address below
        </Typography>

        <TextField
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          value={formData.email}
          onChange={handleInput}
          sx={{ bgcolor: '#f5f5f5', borderRadius: '8px', mb: 2 }}
        />

        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#2ebf91',
            '&:hover': { backgroundColor: '#5c46b2' },
            fontWeight: 'bold',
            padding: '12px',
            mb: 2,
          }}
          onClick={handleSubmit}
        >
          Send Reset Link
        </Button>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link href="/login" variant="body2" sx={{ color: '#2ebf91',  
                fontSize: '12px', 
                fontWeight: '400', 
                textTransform:'uppercase',
                ml: 1, 
                '&:hover': { color: '#000000' } }}>
                        Back to Login
          </Link>
        </Box>

        <Snackbar
          open={snackbarOpen}
          message="Reset link sent successfully!"
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default ForgotPassword;
