import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DoneIcon from '@mui/icons-material/Done';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
 
 
 
 
dayjs.extend(utc);
dayjs.extend(timezone);
 
 
const WorkOrderAssignment = ({ task }) => {
  const [currentTask, setCurrentTask] = useState(task);
  const [timer, setTimer] = useState(0); // Current session timer
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [pauseReason, setPauseReason] = useState(''); // Reason for pausing
  const [location, setLocation] = useState(null);
  const [initialLocation, setInitialLocation] = useState(null);
  const [lastLocationSent, setLastLocationSent] = useState(null);
  const [address, setAddress] = useState('');
  const [isLocationUpdated, setIsLocationUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [action, setAction] = useState('');
  const [initialAddress, setInitialAddress] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
 
  const apiUrl = `${API_BASE_URL}/api/tasks`; // Your API base URL
  
 
  const calculateECT = (elapsedTimeInMillis) => {
    const hours = Math.floor(elapsedTimeInMillis / (1000 * 60 * 60)); // Convert milliseconds to hours
    const days = hours / 24; // Convert hours to days
    return days.toFixed(1); // Return the number of days with one decimal point
  };
 
  // Formatting function for dates and times
  const formatDateTime = (dateTime) => {
    return dayjs(dateTime).format('DD-MM-YYYY HH:mm'); // Format as DD-MM-YYYY HH:mm
  };
 
// Function for fetching and updating location and address during check-in
const fetchLocationAndAddress = async () => {
  try {
    if (!navigator.geolocation) {
      setErrorMessage('Geolocation is not supported by this browser.');
      return;
    }
 
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude }); // Update current location
 
        let addressData = '';
        try {
          // Fetch address from OpenStreetMap API
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
          );
          const data = await response.json();
          addressData = data?.display_name || 'Address not found';
          setAddress(addressData); // Update address for the current location
        } catch (error) {
          console.error('Error fetching address:', error);
          setErrorMessage('Failed to fetch address. Using default location.');
        }
 
        // Store initial location only once when check-in occurs
        if (!initialLocation) {
          console.log("Setting Initial Location:", { latitude, longitude, addressData });
          setInitialLocation({ latitude, longitude });
          setInitialAddress(addressData); // Set initial address
        }
 
        // Format the date and time for check-in
        const checkInDate = new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Kolkata',
        });
        const checkInTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
        const updatedTask = {
          ...currentTask,
          "initial Location": {
            latitude,
            longitude,
            address: addressData,  // Store address for initial location
          },
          "Status": "IN-Route",
          "Check-In Date": checkInDate,
          "Check-In Time": checkInTime,
        };
 
        // Update task in the database with the initial location
        try {
          console.log("Updating Task with Initial Location:", updatedTask);
          await updateTaskInDatabase(updatedTask);
          setCurrentTask(updatedTask);
          setSuccessMessage('Check-in location updated successfully!');
        } catch (error) {
          console.error('Error updating task:', error);
          setErrorMessage('Failed to update task in the database.');
        }
      },
      (error) => {
        console.error('Geolocation error:', error);
        setErrorMessage('Geolocation not allowed or unavailable.');
      }
    );
  } catch (error) {
    console.error('Error in fetchLocationAndAddress:', error);
    setErrorMessage('An unexpected error occurred.');
  }
};
 
  const updateTaskInDatabase = async (updatedData) => {
    try {
      await fetch(`${apiUrl}/${currentTask["WO#"]}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
    } catch (error) {
      console.error('Error updating task in database:', error);
    }
  };
 
  const handleAcknowledge = async () => {
    if (!isRunning) {
      const istDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
      });
      const istTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
      // Fetch current location and address
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
 
            let addressData = '';
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
              );
              const data = await response.json();
              addressData = data?.display_name || 'Address not found';
              setAddress(addressData);
            } catch (error) {
              console.error('Error fetching address:', error);
              setErrorMessage('Failed to fetch address. Using default location.');
            }
 
            // Update task with acknowledgment data for current location
            const updatedTask = {
              ...currentTask,
              "Current Location": {
                latitude,
                longitude,
                address: addressData, // Store address for current location
              },
              "Status": "Started",
              "Acknowledge Date": istDate,
              "Acknowledge Time": istTime,
            };
 
            setCurrentTask(updatedTask);
            setIsRunning(true);
            setAction('acknowledge');
 
            const start = Date.now() - timer;
            const id = setInterval(() => {
              setTimer(Date.now() - start);
            }, 1000);
            setIntervalId(id);
 
            localStorage.setItem('timerState', JSON.stringify({
              woNumber: updatedTask["WO#"],
              startTime: start,
              isRunning: true,
            }));
 
            try {
              await updateTaskInDatabase(updatedTask);
              setSuccessMessage('Acknowledgment location and task updated successfully!');
            } catch (error) {
              console.error('Error updating task:', error);
              setErrorMessage('Failed to update task in the database.');
            }
          },
          (error) => {
            console.error('Geolocation error:', error);
            setErrorMessage('Geolocation not allowed or unavailable.');
          }
        );
      } else {
        setErrorMessage('Geolocation is not supported by this browser.');
      }
    }
  };
 
 
  const handlePause = () => {
    if (isRunning) {
      clearInterval(intervalId);
      setIsRunning(false);
      setShowReasonInput(true);
      localStorage.removeItem('timerState');
 
      const pauseDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
      });
 
      const pauseTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
      const updatedTask = {
        ...currentTask,
        "Status": "Paused",
        "Pause Date": pauseDate,
        "Pause Time": pauseTime,
      };
 
      updateTaskInDatabase(updatedTask);
      setCurrentTask(updatedTask);
    }
  };
 
  const handleSubmitPauseReason = () => {
    const elapsedTime = timer;
    const updatedTask = {
      ...currentTask,
      "Status": "Paused",
      "Elapsed Time": elapsedTime,
      "Pause Reason": pauseReason,
    };
    updateTaskInDatabase(updatedTask);
    setCurrentTask(updatedTask);
    setShowReasonInput(false);
    setPauseReason('');
    localStorage.removeItem('timerState');
  };
 
  const handleResume = () => {
    if (!isRunning) {
      const start = Date.now() - timer;
      const id = setInterval(() => {
        setTimer(Date.now() - start);
      }, 1000);
      setIsRunning(true);
      setIntervalId(id);
      setCurrentTask(prevTask => ({ ...prevTask, "Status": "Resumed" }));
      localStorage.setItem('timerState', JSON.stringify({
        woNumber: currentTask["WO#"],
        startTime: start,
        isRunning: true,
      }));
    }
  };
 
  const handleComplete = () => {
    if (isRunning) {
      clearInterval(intervalId);
      setIsRunning(false);
    }
 
    const finalElapsedTime = timer;
    const ect = calculateECT(timer);

    const istDate = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata',
    });
 
    const istDateTime = dayjs().tz('Asia/Kolkata').format('DD-MM-YYYY HH:mm'); // This formats the date and time in IST
 
    const istTime = new Date().toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' });
 
    const updatedTask = {
      ...currentTask,
      "Status": `Fixed`,
      "Elapsed Time": finalElapsedTime,
      "Time Stamp": formatTime(finalElapsedTime),
      "ECT": ect,
      "Service Completion Date": istDateTime, // Use formatted date-time
      "Completion Time": istTime,
      "Completion Date":istDate,
    };
 
    updateTaskInDatabase(updatedTask);
    setCurrentTask(updatedTask);
 
    setTimer(finalElapsedTime);
    clearInterval(intervalId);
    localStorage.removeItem('timerState');
  };
 
  const handleCheckIn = async () => {
    setAction('checkin');  // Set the action to "checkin" to display the initial location only
    // Fetch initial location only if it hasn't been set already
    if (!initialLocation) {
      fetchLocationAndAddress(); // Only if the initial location hasn't been set
    }
  };
 
   
 
 
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
 
  useEffect(() => {
    const storedTimerState = localStorage.getItem('timerState');
    if (storedTimerState) {
      const { woNumber, startTime, isRunning } = JSON.parse(storedTimerState);
      if (currentTask["WO#"] === woNumber && isRunning) {
        const start = Date.now() - (Date.now() - startTime);
        const id = setInterval(() => {
          setTimer(Date.now() - start);
        }, 1000);
        setIntervalId(id);
        setIsRunning(true);
      }
    }
  }, [currentTask]);
 
  useEffect(() => {
    if (currentTask && currentTask["Elapsed Time"]) {
      setTimer(currentTask["Elapsed Time"]);
    }
    return () => clearInterval(intervalId);
  }, [currentTask, intervalId]);
 
  return (
    <Box padding={2}>
      <Typography variant="h5" gutterBottom>
        Work Order Assignment for {currentTask["WO#"]}
      </Typography>
 
      <Typography variant="h6">Status: {currentTask["Status"]}</Typography>
      <Typography variant="h6">Elapsed Time: {formatTime(timer)}</Typography>
      <Typography variant="h6">Time Stamp: {currentTask["Time Stamp"]}</Typography>
      <Typography variant="h6">ECT: {currentTask["ECT"]} days</Typography>
      <Typography variant="h6">Service Completion Date: {currentTask["Service Completion Date"] || "Not Available"}</Typography>
      {/* <Typography variant="h6">Completion Time: {currentTask["Completion Time"]}</Typography> */}
      <Typography variant="h6">Acknowledge Date: {currentTask["Acknowledge Date"]}</Typography>
      <Typography variant="h6">Acknowledge Time: {currentTask["Acknowledge Time"]}</Typography>
      <Typography variant="h6">Check-In Date: {currentTask["Check-In Date"] || "Not Available"}</Typography>
      <Typography variant="h6">Check-In Time: {currentTask["Check-In Time"] || "Not Available"}</Typography>
      <Typography variant="h6">Pause Date: {currentTask["Pause Date"] || "Not Available"}</Typography>
      <Typography variant="h6">Pause Time: {currentTask["Pause Time"] || "Not Available"}</Typography>
 
 
      {currentTask["Pause Reason"] && (
        <Typography variant="h6" color="error" sx={{ mt: 2 }}>
          Pause Reason: {currentTask["Pause Reason"]}
        </Typography>
      )}
 
      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
     
              {/* Check In Button */}
              <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          onClick={handleCheckIn}
          disabled={
            currentTask["Status"] === "Fixed" ||
            currentTask["Status"] === "Started" ||
            currentTask["Status"] === "Resumed" ||
            currentTask["Status"] === "Paused"
          }
          sx={{
            backgroundColor: '#BC3232',
            '&:hover': { backgroundColor: '#9E2C2C' },
          }}
        >
          Check In
        </Button>
       
       
        {/* Acknowledge Button */}
        <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          onClick={handleAcknowledge}
          disabled={
            currentTask["Status"] === "Fixed" ||
            currentTask["Status"] === "Started" ||
            currentTask["Status"] === "Resumed" ||
            currentTask["Status"] === "Paused"
          }
          sx={{
            backgroundColor: '#BC3232',
            '&:hover': { backgroundColor: '#9E2C2C' },
          }}
        >
          ON-Site
        </Button>
 
       {/* Pause Button */}
       <Button
          variant="contained"
          startIcon={<PauseCircleIcon />}
          onClick={handlePause}
          disabled={
            currentTask["Status"] === "Fixed" ||
            !isRunning ||
            currentTask["Status"] === "Paused"
          }
          sx={{
            backgroundColor: '#BC3232',
            '&:hover': { backgroundColor: '#9E2C2C' },
          }}
        >
          Pause
        </Button>
 
              {/* Resume Button */}
              <Button
          variant="contained"
          startIcon={<PlayCircleIcon />}
          onClick={handleResume}
          disabled={
            currentTask["Status"] === "Fixed" ||
            isRunning ||
            currentTask["Status"] === "Started"
          }
          sx={{
            backgroundColor: '#32BC32',
            '&:hover': { backgroundColor: '#2C9E2C' },
          }}
        >
          Resume
        </Button>
 
        <Button
          variant="contained"
          startIcon={<DoneIcon />}
          onClick={handleComplete}
          disabled={currentTask["Status"] === "Fixed"}
          sx={{
            backgroundColor: '#BC3232',
            '&:hover': { backgroundColor: '#9E2C2C' },
          }}
        >
          Complete
        </Button>
     
      </Box>
 
      {showReasonInput && (
        <Box mt={3}>
          <Typography variant="h6">Reason for Pausing:</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={pauseReason}
            onChange={(e) => setPauseReason(e.target.value)}
            variant="outlined"
            placeholder="Enter the reason for pausing the task"
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmitPauseReason}
            disabled={!pauseReason}
          >
            Submit Pause Reason
          </Button>
        </Box>
      )}
 
       {action === 'checkin' && initialLocation && (
  <Typography variant="h6" sx={{ mt: 2 }}>
    Initial Location: Latitude: {initialLocation.latitude}, Longitude: {initialLocation.longitude}
  </Typography>
)}
 
{action === 'checkin' && initialLocation && address && (
  <Typography variant="h6">
    Address: {address}
  </Typography>
)}
 
{action === 'acknowledge' && location && (
  <Typography variant="h6" sx={{ mt: 2 }}>
    Current Location: Latitude: {location.latitude}, Longitude: {location.longitude}
  </Typography>
)}
 
{action === 'acknowledge' && address && (
  <Typography variant="h6">
    Address: {address}
  </Typography>
)}
      <style>{`
        @keyframes blink {
          0%, 100% { opacity: 1; }
          50% { opacity: 0; }
        }
      `}</style>
 
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      {successMessage && <Typography color="success">{successMessage}</Typography>}
    </Box>
  );
};
 
export default WorkOrderAssignment;