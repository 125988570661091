import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton, Snackbar, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../services/apiService';

const ChangePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ oldPassword: '', password: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false }); // Toggle password visibility

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/verify-reset-token/${token}`);
        if (!res.data.success) {
          setError('Invalid or expired token. Redirecting...');
          setTimeout(() => navigate('/login'), 3000);
        }
      } catch {
        setError('Invalid or expired token. Redirecting...');
        setTimeout(() => navigate('/login'), 3000);
      }
    };
    verifyToken();
  }, [token, navigate]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = async () => {
    setError(''); // Reset previous errors
  
    // Check if all fields are filled
    if (!formData.oldPassword || !formData.password || !formData.confirmPassword) {
      setError('All fields are required.');
      return;
    }
  
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
  
    // Check password length (min 8, max 12 characters) and required characters
    const password = formData.password;
    const isValidPassword = /^(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,12}$/;
    if (!isValidPassword.test(password)) {
      setError("Password must be 8-12 characters long, contain at least one number, and one special character!");
      return;
    }
  
    try {
      // Send the request to the server
      const res = await axios.post(`${API_BASE_URL}/api/reset-password`, {
        token,
        oldPassword: formData.oldPassword,
        password: formData.password,
      });
  
      if (res.data.success) {
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate('/login');
        }, 2000);
      } else {
        setError(res.data.msg || 'An error occurred. Please try again.');
      }
    } catch (err) {
      setError(err.response?.data?.msg || 'An error occurred. Please try again.');
    }
  };
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', bgcolor: '#f0f2f5' }}>
      <Paper elevation={6} sx={{ padding: '40px', borderRadius: '15px', width: '100%', maxWidth: '500px', bgcolor: '#ffffff' }}>
        <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', color: 'black', fontWeight: '300', fontSize:"30px"}}>
          Reset Your Password
        </Typography>

        {error && (
          <Typography color="error" sx={{ textAlign: 'center', mb: 3, fontWeight: '300' }}>
            {error}
          </Typography>
        )}

        {/* Temporary Password (Always Visible) */}
        <TextField
          label="Temporary Password"
          name="oldPassword"
          variant="outlined"
          fullWidth
          type="text" // Always visible
          value={formData.oldPassword}
          onChange={handleInput}
          sx={{ bgcolor: '#f5f5f5', borderRadius: '8px', mb: 2 }}
        />

        {/* New Password Field */}
        <TextField
          label="New Password"
          name="password"
          variant="outlined"
          fullWidth
          type={showPassword.password ? 'text' : 'password'} // Toggle visibility
          value={formData.password}
          onChange={handleInput}
          sx={{ bgcolor: '#f5f5f5', borderRadius: '8px', mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handlePasswordVisibility('password')}>
                  {showPassword.password ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* Confirm Password Field */}
        <TextField
          label="Confirm New Password"
          name="confirmPassword"
          variant="outlined"
          fullWidth
          type={showPassword.confirmPassword ? 'text' : 'password'} // Toggle visibility
          value={formData.confirmPassword}
          onChange={handleInput}
          sx={{ bgcolor: '#f5f5f5', borderRadius: '8px', mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handlePasswordVisibility('confirmPassword')}>
                  {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          fullWidth
          sx={{mt:2,
            backgroundColor: '#2ebf91',
            '&:hover': { backgroundColor: '#5c46b2' },
            fontWeight: 'bold',
            padding: '12px',
            mb: 2,
          }}
          onClick={handleSubmit}
        >
          Reset Password
        </Button>

        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}
          message="Password reset successful!"
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen(false)}
        />
      </Paper>
    </Box>
  );
};

export default ChangePassword;
