import React, { useState } from 'react';
import { Box, Button, TextField, Typography, MenuItem, Snackbar, Link, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png'; // Replace with your logo path
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons


const Login = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({ username: '', password: '', role: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setError(''); // Clear any previous error message
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async () => {
    setError(''); // Reset error state on login attempt

    // Validation check for empty fields
    if (!loginData.username || !loginData.password || !loginData.role) {
      setError('All fields are required!');
      return;
    }

    try {
      // Call the API to validate user credentials
      const res = await axios.post(`${API_BASE_URL}/api/login`, loginData);

      // If login is successful, save the token and user data
      const token = res.data.token;
      const userId = res.data.userId;
      const userRole = res.data.role;

      // Store token and user data in localStorage or sessionStorage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('role', userRole);

      // Check the role and navigate to the correct dashboard
      if (res.data.role === 'admin') {
        setSnackbarMessage('Successfully logged in as Admin!');
        localStorage.setItem('loggedInUser', JSON.stringify({ username: loginData.username, role: res.data.role }));
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/admin'); // Navigate to Admin Dashboard
        }, 2000); // Delay for 2 seconds to show the success message
      } else if (res.data.role === 'field-engineer') {
        setSnackbarMessage('Successfully logged in as Field Engineer!');
        localStorage.setItem('loggedInUser', JSON.stringify({ username: loginData.username, role: res.data.role }));
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/field-engineer'); // Navigate to Field Engineer Dashboard
        }, 2000); // Delay for 2 seconds to show the success message
      }
    } catch (error) {
      // Handle errors from the API request
      if (error.response) {
        // Handle blocked user or invalid credentials error
        if (error.response.status === 403) {
          setError('Invalid credentials, please contact admin');
        } else {
          setError(error.response.data.msg || 'An error occurred');
        }
      } else if (error.request) {
        // No response was received from the server
        setError('No response from the server');
      } else {
        // Something else went wrong while setting up the request
        setError('An error occurred while setting up the request');
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      {/* Add your logo here */}
      <Box sx={{ mb: 3 }}>
      <img src={logo} alt="Logo" style={{ width: '80px', height: '80px' }} /> 
      </Box>

      <Typography variant="h4" sx={{ mb: 3 }}>Login</Typography>

      {error && (
        <Typography color="error" sx={{ textAlign: 'center', mb: 3, fontWeight: '400' }}>
          {error}
        </Typography>
      )}

      <TextField
        label="Username"
        name="username"
        variant="outlined"
        sx={{ mb: 2, width: '300px' }}
        value={loginData.username}
        onChange={handleInputChange}
      />
        <TextField
          label="Password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          sx={{ mb: 2, width: '300px' }}
          value={loginData.password} // Correct variable name
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      <TextField
        select
        label="Login as"
        name="role"
        value={loginData.role}
        onChange={handleInputChange}
        sx={{ mb: 2, width: '300px' }}
      >
        <MenuItem value="admin">Admin</MenuItem>
        <MenuItem value="field-engineer">Field Engineer</MenuItem>
      </TextField>
      <Button variant="contained" sx={{ width: '300px', backgroundColor: '#2ebf91' }} onClick={handleLogin}>
        Login
      </Button>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
  {/* Forgot Password Link */}
  <Link 
    href="/ForgotPassword" 
    variant="body2" 
    sx={{ 
      color: '#000000',
      fontWeight: '400',
      fontSize: '14px', 
      textDecoration: 'none',  
      '&:hover': { color: '#2ebf91' }  
    }}
  >
    Forgot Password?
  </Link>

 
  <Link 
    href="/register"  
    variant="body2" 
    sx={{ 
      color: '#2ebf91',  
      fontSize: '14px', 
      fontWeight: '400', 
      textTransform:'uppercase',
      ml: 1, 
      '&:hover': { color: '#000000' }
    }}
  >
    Sign Up
  </Link>
</Box>


      {/* Snackbar for success message */}
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
      />
    </Box>
  );
};

export default Login;
