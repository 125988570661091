import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  CircularProgress,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import TicketDetails from '../components/TicketDetails'; // Adjust the import path as needed
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';




 
// Define a function to get the card style based on status
const getStatusColor = (status) => {
  switch (status) {
    case 'Fixed':
      return { backgroundColor: '#cce5ff', border: '2px solid #007bff' }; // Light Blue
    case 'Rescheduled':
      return { backgroundColor: '#fff3e6', border: '2px solid #ff7d4d' }; // Light Orange
    case 'Open':
      return { backgroundColor: '#d4edda', border: '2px solid #28a745' }; // Green
    case 'Accepted':
      return { backgroundColor: '#fff4e6', border: '2px solid #ffcc00' }; // Light Yellow
    default:
      return { backgroundColor: '#ffffff', border: '2px solid #ced4da' }; // Default (light gray)
  }
};
 
const AnimatedCard = styled(Card)(({ theme }) => ({
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));
 
const TicketManagement = () => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null); // State for selected ticket
  const [drawerOpen, setDrawerOpen] = useState(false);
 
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/tickets`); // Adjust URL if necessary
        if (!response.ok) {
          throw new Error('Failed to fetch tickets');
        }
        const data = await response.json();
        setTickets(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
 
    fetchTickets();
  }, []);
 
  if (loading) {
    return <CircularProgress />;
  }
 
  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }
 
  // Group tickets by status
  const groupedTickets = tickets.reduce((acc, ticket) => {
    const status = ticket["Status"];
    if (!acc[status]) {
      acc[status] = [];
    }
    acc[status].push(ticket);
    return acc;
  }, {});
 
  // Close ticket details
  const handleCloseDetails = () => {
    setSelectedTicket(null);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };


 
  return (
<Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative' }}>
  {/* SideDrawer */}
  <Box
    sx={{
      width: drawerOpen ? '240px' : '0', 
      flexShrink: 0,
      transition: 'width 0.3s ease',
      backgroundColor: '#fff',
      boxShadow: drawerOpen ? '2px 0 5px rgba(0, 0, 0, 0.1)' : 'none',
      height: '100vh', 
      position: 'fixed', 
       top: 0,
      left: 0,
      zIndex: 1100, 
      overflowY: 'auto',
    }}
  >
    <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
  </Box>

  {/* Main content wrapper */}
  <Box
    sx={{
      marginLeft: drawerOpen ? '180px' : '0', 
      transition: 'margin-left 0.3s ease',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    }}
  >
    {/* Header */}
    <Box
      sx={{
        position: 'fixed', 
        top: 0,
        left: 0,
        width: '100%',
        height: '64px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1200,
      }}
    >
      <Header handleDrawerToggle={handleDrawerToggle} title="Admin Dashboard" />
    </Box>

    {/* Main content area */}
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'auto', 
        padding: '20px',
        backgroundColor: '#f0f0f0',
        marginTop: '64px', 
        marginLeft:7,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'secondary.main', 
          padding: '10px 20px', 
          borderRadius: '8px', 
          marginBottom: '20px', 
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: '400', 
            color: '#fff', 
            textTransform: 'uppercase', 
            letterSpacing: '6px',
            textAlign:'center',
          }}
        >
          Ticket Management
        </Typography>
      </Box>


      <Grid container spacing={2}>
        {Object.keys(groupedTickets).map(status => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={status}>
            <Box sx={{border: '1px solid #ccc', borderRadius: '8px', padding: '30px', backgroundColor: '#f9f9f9' }}>
              <Typography variant="h5" gutterBottom>{status}</Typography>
              {groupedTickets[status].length > 0 ? (
                groupedTickets[status].map(ticket => {
                  const statusStyles = getStatusColor(ticket["Status"]);
                  return (
                    <AnimatedCard
                      sx={{ ...statusStyles, marginBottom: '20px' }}
                      key={ticket._id}
                      onClick={() => setSelectedTicket(ticket)}
                      style={{ cursor: 'pointer' }}
                    >
                      <CardContent>
                        <Typography variant="h6">{ticket["Tech Assignment"]}</Typography>
                        <Typography>Case#: {ticket["Case#"]}</Typography>
                        <Typography>WO#: {ticket["WO#"]}</Typography>
                        <Typography>MSN#: {ticket["MSN#"]}</Typography>
                        <Typography>Contact: {ticket["Customer Mobile Number"]}</Typography>
                        <Typography>Updated By: {ticket["Updated By"]}</Typography>
                        <Typography>Status: {ticket["Status"]}</Typography>
                      </CardContent>
                      <CardActions>
                        {/* Optional actions */}
                      </CardActions>
                    </AnimatedCard>
                  );
                })
              ) : (
                <Typography>No tickets available</Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Render TicketDetails if a ticket is selected */}
      {selectedTicket && <TicketDetails ticket={selectedTicket} onClose={handleCloseDetails} />}
    </Box>

    {/* Footer */}
    <Box
      sx={{
        flexShrink: 0,
        height: '45px', 
        backgroundColor: '#fff',
        boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Footer />
    </Box>
  </Box>
</Box>

   
  );
};
export default TicketManagement;
 
 
