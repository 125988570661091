import React, { useState } from 'react';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from '../components/Header';
import FieldEngineerDrawer from '../components/FieldEngineerDrawer';  
import Footer from '../components/Footer';
import WorkOrderAssignment from '../components/WorkOrderAssignment';  // Import WorkOrderAssignment component
import AssignOrderList from '../components/AssignOrderList';  // Import AssignOrderList component

const FieldEngineerDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('Dashboard');  // Track the current menu
  const [selectedTask, setSelectedTask] = useState(null);  // State to store the selected task

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuClick = (menuItem) => {
    setCurrentMenu(menuItem);  // Update the current menu based on what is clicked
  };

  const handleAcknowledgeTask = (task) => {
    setSelectedTask(task);  // Set the selected task data
    setCurrentMenu('WorkOrderAssignment');  // Switch to WorkOrderAssignment view
  };

  // Define different components based on the selected menu item
  const renderContent = () => {
    if (currentMenu === 'WorkOrderAssignment') {
      return <WorkOrderAssignment task={selectedTask} />;  // Pass the selected task data
    } else if (currentMenu === 'AssignOrderList') {
      return <AssignOrderList onAcknowledgeTask={handleAcknowledgeTask} />;  // Pass acknowledge handler
    } else {
      return (
        <Box
          sx={{
            p: 3,
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}
        >
          <h3>Welcome to the Field Engineer Dashboard</h3>
          <p>Here you can manage work orders, update your profile, and handle tasks.</p>
        </Box>
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />

      {/* Header for Field Engineer Dashboard */}
      <Header handleDrawerToggle={handleDrawerToggle} title="Field Engineer Dashboard" />

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Field Engineer specific Drawer */}
        <FieldEngineerDrawer
          open={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          onMenuClick={handleMenuClick}  // Pass handleMenuClick to FieldEngineerDrawer
        />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: 'margin 0.3s ease-out',
            backgroundColor: '#f4f6f8',
            paddingTop: '64px',  // Offset for the fixed header
          }}
        >
          <Toolbar />

          {/* Render content based on the selected menu */}
          {renderContent()}
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default FieldEngineerDashboard;
