import React, { useState } from 'react';
import { Box, Button, TextField, Typography, MenuItem, Snackbar, FormControl,Link,  InputLabel, Select, Grid, Paper, IconButton, InputAdornment, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // to handle API requests
import logo from '../assets/logo.png'; // Replace with your logo path
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { API_BASE_URL } from '../services/apiService';// Import Material UI Icons

const Register = () => {
  const navigate = useNavigate();
  const [userType, setUserType] = useState(''); // Store selected user type
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword:'',
    contactNumber: '',
    firstname:'',
    surename: '',
    postalCode: '',
    proficiency: '',
    state:'',
    city: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState('');//Add error

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const capitalizedFields = ['firstname', 'surename', 'state', 'city'];
    const newValue = capitalizedFields.includes(name) ? capitalizeFirstLetter(value) : value;

    setFormData({ ...formData, [name]: newValue });
    setError("");
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
      
    // Basic validation
    const requiredFields = [
      "username",
      "email",
      "password",
      "confirmPassword",
      "firstname",
      "surename",
      "contactNumber",
      "postalCode",
      "state",
      "city",
    ];
  
    for (let field of requiredFields) {
      if (!formData[field]) {
        setError(`All fields are required!`);
        return;
      }
    }

     // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setError("Invalid email format");
      return;
    }

    // Contact number validation
    const contactNumber = formData.contactNumber.trim();

    // Indian contact number validation
    const contactNumberRegex = /^[1-9]{1}[0-9]{9}$/;
    const sequentialAsc = '0123456789';
    const sequentialDesc = '9876543210';
    const repetitiveNumbers = /^(.)\1{9}$/;  // checks for repeated numbers like 1111111111
    
    if (!contactNumberRegex.test(contactNumber)) {
      setError("Invalid contact number. It must be a 10-digit number starting from 1-9.");
      return;
    }
    // Disallow sequential or repetitive numbers
    if (
      contactNumber === sequentialAsc || 
      contactNumber === sequentialDesc || 
      repetitiveNumbers.test(contactNumber)
    ) {
      setError("Sequential or repetitive contact numbers are not allowed.");
      return;
    }
    if (!/^\d{10}$/.test(contactNumber)) {
      setError("Contact number must be exactly 10 digits with no letters or special characters.");
      return;
    }

    // Postal code validation (exactly 6 digits)
    const postalCode = formData.postalCode.trim();
    const postalCodeRegex = /^[0-9]{6}$/;
    if (!postalCodeRegex.test(postalCode) || postalCode === '000000') {
       setError({message: 'Postal code must be exactly 6 digits.'});
    }

    // Password validation
    if(formData.password !== formData.confirmPassword){
      setError("Passwords do not match!");
      return;
    }
    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters!");
      return;
    }
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharPattern.test(formData.password)) {
      setError("Password must contain at least one special character!");
      return;
    }
    // Check for at least one alphanumeric or special character (any allowed character)
    const anyCharPattern = /[A-Za-z0-9!@#$%^&*(),.?":{}|<>]/;
    if (!anyCharPattern.test(formData.password)) {
      setError("Password must contain at least one character!");
      return;
    }
    const password = formData.password;
    const isValidPassword = /^(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,12}$/;
    if (!isValidPassword.test(password)) {
      setError("Password must be 8-12 characters long, contain at least one number, and one special character!");
      return;
    }

    // First name validation (only letters)
    const namePattern = /^[A-Za-z]+$/;
    if (!namePattern.test(formData.firstname)) {
      setError("First name must contain only letters.");
      return;
    }

    // Surname validation (only letters)
    if (!namePattern.test(formData.surename)) {
      setError("Last name must contain only letters.");
      return;
    }

    // State validation (only letters and spaces)
    const statePattern = /^[A-Za-z\s]+$/;
    if (!statePattern.test(formData.state)) {
      setError("State must contain only letters.");
      return;
    }

    // Validate city (only letters and spaces)
    const cityPattern = /^[A-Za-z\s]+$/;
    if (!cityPattern.test(formData.city)) {
      setError("City must contain only letters.");
      return;
    }

    setError("");
    
    try {
      const response = await axios.post(`${API_BASE_URL}/api/register`, {
        ...formData,
        role: userType,
      });

      if (response.status === 201 && response.data?.message === "User registered successfully") {
        setSnackbarOpen(true);
        setTimeout(() =>{
          setSnackbarOpen(false);
            navigate('/login');
           }, 2000);
      } else {
        setError(response.data?.message || "Registration failed"); 
      }
    } catch (error) {
      console.error("Registration Failed:", error.response ? error.response.data : error.message);
      if (error.response) {
        const { field, message } = error.response.data;
        
        // Display specific error messages
        if (field === "email") {
            setError("The email you entered already exists.");
        } else if (field === "contactNumber") {
            setError("The contact number you entered already exists.");
        } else {
            setError(message || "An error occurred during registration.");
        }
    } else {
        setError("An unexpected error occurred. Please try again.");
    }
}
};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', mb: '15px', bgcolor:'#f0f2f5'
      
     }}>
      <Paper elevation={6} sx={{ padding: '40px', borderRadius: '15px', width: '100%', maxWidth: '550px',bgcolor: '#ffffff' }}>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '60px', height: '60px' }} />
        </Box>
        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>Register</Typography>

        {error && <Typography sx={{ color: 'red',fontWeight:"300", textAlign: 'center', mt: 3, mb:2 }}>{error}</Typography>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Username"
              name="username"
              variant="outlined"
              fullWidth
              value={formData.username}
              onChange={handleInputChange}
              sx={{ bgcolor: '#f5f5f5', borderRadius: '8px' }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={handleInputChange}
              sx={{ bgcolor: '#f5f5f5', borderRadius: '8px' }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formData.password}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              value={formData.confirmPassword}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              label="Register as"
              value={userType}
              fullWidth
              onChange={handleUserTypeChange}
              sx={{ bgcolor: '#f5f5f5', borderRadius: '8px' }}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="field-engineer">Field Engineer</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {/* Conditional rendering for Admin fields */}
        { userType === 'admin' && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstname"
                variant="outlined"
                fullWidth
                value={formData.firstname}
                onChange={handleInputChange}
                sx={{bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px'}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="surename"
                variant="outlined"
                fullWidth
                value={formData.surename}
                onChange={handleInputChange}
                sx={{bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px'}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Contact Number"
                name="contactNumber"
                variant="outlined"
                fullWidth
                value={formData.contactNumber}
                onChange={handleInputChange}
                inputProps={{ maxLength: 10, pattern: "\\d*" }}
                sx={{bgcolor: '#f5f5f5', borderRadius: '8px'}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="state"
                variant="outlined"
                fullWidth
                value={formData.state}
                onChange={handleInputChange}
                sx={{bgcolor: '#f5f5f5', borderRadius: '8px'}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                variant="outlined"
                fullWidth
                value={formData.city}
                onChange={handleInputChange}
                sx={{bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px'}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Postal Code"
                name="postalCode"
                variant="outlined"
                fullWidth
                value={formData.postalCode}
                onChange={handleInputChange}
                inputProps={{ maxLength: 6, minLength: 6 }} 
                sx={{bgcolor: '#f5f5f5', borderRadius: '8px'}}
              />
            </Grid>
          </Grid>
        )}

        {/* Conditional rendering for Field Engineer fields */}
        {userType === 'field-engineer' && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstname"
                variant="outlined"
                fullWidth
                value={formData.firstname}
                onChange={handleInputChange}
                sx={{ bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Surname"
                name="surename"
                variant="outlined"
                fullWidth
                value={formData.surename}
                onChange={handleInputChange}
                sx={{ bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px' }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                label="Contact Number"
                name="contactNumber"
                variant="outlined"
                fullWidth
                value={formData.contactNumber}
                onChange={handleInputChange}
                inputProps={{ maxLength: 10, pattern: "\\d*" }}
                sx={{ bgcolor: '#f5f5f5', borderRadius: '8px' }}
              />
            </Grid>

        
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="state"
                variant="outlined"
                fullWidth
                value={formData.state}
                onChange={handleInputChange}
                sx={{ bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                variant="outlined"
                fullWidth
                value={formData.city}
                onChange={handleInputChange}
                sx={{ bgcolor: '#f5f5f5',textTransform:'capitalize', borderRadius: '8px' }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Postal Code"
                name="postalCode"
                variant="outlined"
                fullWidth
                value={formData.postalCode}
                onChange={handleInputChange}
                inputProps={{ maxLength: 6, minLength: 6 }} 
                sx={{ bgcolor: '#f5f5f5', borderRadius: '8px' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Proficiency</InputLabel>
                <Select
                  name="proficiency"
                  value={formData.proficiency}
                  onChange={handleInputChange}
                  sx={{ bgcolor: '#f5f5f5', borderRadius: '8px', }}
                >
                  <MenuItem value="Elite" sx={{ textAlign: 'center', color: '#333' }}>Elite</MenuItem>
                  <MenuItem value="Core" sx={{ textAlign: 'center', color: '#333' }}>Core</MenuItem>
                  <MenuItem value="Advance" sx={{ textAlign: 'center', color: '#333' }}>Advance</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

       
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleSubmit} variant="contained" 
                  sx={{
                    width: '100%', 
                    backgroundColor: '#E52031', 
                    mt: 2, 
                    '&:hover': { backgroundColor: '#E53010' }, 
                    fontWeight: 'bold', 
                    padding: '12px'
                  }}>Register</Button>
        </Box>
                <Box 
          sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            textAlign: 'center' 
          }}
        >
          {/* Non-link Text */}
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#000000', 
              fontWeight: '400', 
              fontSize: '14px', 
              marginRight: '5px' // Adds some space between the text and the link
            }}
          >
            Already have an account?
          </Typography>

          {/* Link to Login */}
          <Link 
            href="/login"  
            variant="body2" 
            sx={{ 
              color: '#2ebf91',  
              fontSize: '14px', 
              fontWeight: '400', 
              textTransform: 'uppercase',
              '&:hover': { color: '#000000' }
            }}
          >
            Log In
          </Link>
        </Box>

      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Registration successful"
      />
    </Box>  );
};

export default Register;
