import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  MenuItem,
  Select,
  Toolbar,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DatePicker,

  TablePagination,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

// Custom Components
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import isBetween from 'dayjs/plugin/isBetween';

// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);

// Example usage
const isDateInRange = dayjs('2024-12-06').isBetween('2024-12-01', '2024-12-31');
console.log(isDateInRange); // Output: true

// Style constants
const boxShadowStyle = '0 4px 10px rgba(0, 0, 0, 0.1)';
const headerFont = "'Roboto', sans-serif";
const primaryColor = '#1565C0';

// Helper functions
const formatDateTime = (dateString) => {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return 'N/A';
  }
  const date = new Date(dateString);
  return `${date.toLocaleDateString()}, ${date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
};

const calculateECT = (shippedDate, completionDate) => {
  if (
    !shippedDate ||
    !completionDate ||
    isNaN(new Date(shippedDate).getTime()) ||
    isNaN(new Date(completionDate).getTime())
  ) {
    return 'N/A';
  }
  const timeDiff = new Date(completionDate) - new Date(shippedDate);
  return (timeDiff / (1000 * 60 * 60 * 24)).toFixed(2); // Convert ms to days
};

const getECTRowColor = (ect) => {
  if (ect === 'N/A' || isNaN(ect)) return '#FFF176'; // Yellow for invalid ECT
  if (ect > 1.6) return '#EF9A9A'; // Red for above threshold
  return '#A5D6A7'; // Green for within threshold
};

const ECTDetails = () => {
  const location = useLocation();
  const { taskData } = location.state || {};

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCity, setOpenCity] = useState({});
  const [filters, setFilters] = useState({
    city: '',
    program: '',
    color: '',
    vendorId: '',
    State: '',
    PUDO_ID: '',
    State_Location: '',
    Customer_Postal_Code: '',
    dateRange: '', // new filter for date range
    customStartDate: null, // Custom start date for range
    customEndDate: null,  // Custom end date for range
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState(''); // Initialize state for WO# search

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  // Validate taskData before using it in calculations
  const tasksByCity = useMemo(() => {
    if (!Array.isArray(taskData)) return {}; // Check for valid array
    return taskData
      .slice() // Make a shallow copy to avoid mutating the original data
      .sort((a, b) => new Date(b['Service Completion Date']) - new Date(a['Service Completion Date'])) // Sort descending
      .reduce((acc, task) => {
        const city = task.City || 'Unknown';
        const ect = calculateECT(task['Part Shipped/Delivered Date'], task['Service Completion Date']);
        const colorCategory = ect === 'N/A' ? 'Yellow' : ect > 1.6 ? 'Red' : 'Green';
  
        if (!acc[city]) acc[city] = [];
        acc[city].push({ ...task, ect, colorCategory });
        return acc;
      }, {});
  }, [taskData]);
  
  
  

  const applyDateFilter = (taskDate) => {
    const { dateRange, customStartDate, customEndDate } = filters;
    const taskCompletionDate = new Date(taskDate);
  
    // Validate Custom Date Range
    if (dateRange === 'Custom Range' && customStartDate && customEndDate) {
      if (new Date(customStartDate) > new Date(customEndDate)) {
        // Display an error if the start date is after the end date
        alert('Start Date cannot be later than End Date');
        return false;
      }
    }
  
    switch (dateRange) {
      case 'Today':
        return dayjs().isSame(taskCompletionDate, 'day');
      case 'Past 7 Days':
        return dayjs().subtract(7, 'day').isBefore(taskCompletionDate);
      case 'This Week':
        return dayjs().startOf('week').isBefore(taskCompletionDate);
      case 'Last Month':
        return dayjs().subtract(1, 'month').isBefore(taskCompletionDate);
      case 'Custom Range':
        if (customStartDate && customEndDate) {
          return dayjs(taskCompletionDate).isBetween(dayjs(customStartDate), dayjs(customEndDate), 'day', '[]');
        }
        return true; // If no custom range is provided, don't filter by date
      default:
        return true; // No date filter applied
    }
  };
  

  const filteredTasksByCity = useMemo(() => {
    return Object.keys(tasksByCity).reduce((acc, city) => {
      if (filters.city && city !== filters.city) return acc;
  
      const cityTasks = tasksByCity[city]
        .filter((task) => {
          const matchesVendorId = filters.vendorId ? task['Vendor Id'] === filters.vendorId : true;
          const matchesPUDO_ID = filters.PUDO_ID ? task['PUDO ID'] === filters.PUDO_ID : true;

          const matchesProgram = filters.program ? task['Program'] === filters.program : true;
          const matchesColor = filters.color ? task.colorCategory === filters.color : true;
          const matchesState = filters.State ? task.State === filters.State : true;
          const matchesStateLocation = filters.State_Location
            ? task['State Location'] === filters.State_Location
            : true;
          const matchesCustomerPostalCode = filters.Customer_Postal_Code
            ? task['Customer Postal Code'] === filters.Customer_Postal_Code
            : true;
          const matchesDateRange = applyDateFilter(task['Service Completion Date']);
          const matchesSearchQuery =
            task['WO#'] && task['WO#'].toString().toLowerCase().includes(searchQuery.toLowerCase());
  
          return (
            matchesVendorId &&
            matchesProgram &&
            matchesColor &&
            matchesState &&
            matchesPUDO_ID &&
            matchesStateLocation &&
            matchesCustomerPostalCode &&
            matchesDateRange &&
            matchesSearchQuery
          );
        })
        .sort((a, b) => new Date(b['Service Completion Date']) - new Date(a['Service Completion Date'])); // Sort descending
  
      if (cityTasks.length > 0) {
        acc[city] = cityTasks;
      }
      return acc;
    }, {});
  }, [tasksByCity, filters, searchQuery]);


  const calculateStateLocationAverageECT = () => {
    if (!filters.State_Location || !filteredTasksByCity) return 'N/A';
  
    const tasksInStateLocation = Object.values(filteredTasksByCity)
      .flat()
      .filter(task => task['State Location'] === filters.State_Location);
  
    if (tasksInStateLocation.length === 0) return 'N/A';
  
    const totalECT = tasksInStateLocation.reduce((sum, task) => {
      const ectValue = parseFloat(task.ect);
      return isNaN(ectValue) ? sum : sum + ectValue;
    }, 0);
  
    return (totalECT / tasksInStateLocation.length).toFixed(2); // Average ECT

  };



  const calculatePudoIdAverageECT = () => {
    if (!filters.PUDO_ID || !filteredTasksByCity) return 'N/A';
  
    const tasksInPudoId = Object.values(filteredTasksByCity)
      .flat()
      .filter(task => task['PUDO ID'] === filters.PUDO_ID);
  
    if (tasksInPudoId.length === 0) return 'N/A';
  
    const totalECT = tasksInPudoId.reduce((sum, task) => {
      const ectValue = parseFloat(task.ect);
      return isNaN(ectValue) ? sum : sum + ectValue;
    }, 0);
  
    return (totalECT / tasksInPudoId.length).toFixed(2); // Average ECT
  };
  
  
  

  const handleRowClick = (city) => {
    setOpenCity((prevState) => ({
      ...prevState,
      [city]: !prevState[city],
    }));
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedCities = useMemo(() => {
    const cities = Object.keys(filteredTasksByCity);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return cities.slice(startIndex, endIndex);
  }, [filteredTasksByCity, page, rowsPerPage]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header handleDrawerToggle={handleDrawerToggle} title="City-wise ECT Details" />

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: '#f4f6f8',
            paddingTop: '64px',
            borderRadius: '8px',
            boxShadow: boxShadowStyle,
          }}
        >
          <Toolbar />

          <Typography variant="h4" sx={{ fontFamily: headerFont, color: primaryColor, fontWeight: 'bold' }}>
            City-wise ECT (Average)
          </Typography>

          {/* Filters */}
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <TextField
              label="Search by WO#"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: '250px' }}
            />

<Select
              value={filters.State}
              onChange={(e) => handleFilterChange('State', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All States</MenuItem>
              {[...new Set(taskData.map((task) => task.State))].map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={filters.State_Location}
              onChange={(e) => handleFilterChange('State_Location', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All State Locations</MenuItem>
              {[...new Set(taskData.map((task) => task['State Location']))].map((location, index) => (
                <MenuItem key={index} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={filters.vendorId}
              onChange={(e) => handleFilterChange('vendorId', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Vendor IDs</MenuItem>
              {[...new Set(taskData.map((task) => task['Vendor Id']))].map((vendorId, index) => (
                <MenuItem key={index} value={vendorId}>
                  {vendorId}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={filters.PUDO_ID}
              onChange={(e) => handleFilterChange('PUDO_ID', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All PUDO IDs</MenuItem>
              {[...new Set(taskData.map((task) => task['PUDO ID']))].map((pudo, index) => (
                <MenuItem key={index} value={pudo}>
                  {pudo}
                </MenuItem>
              ))}
            </Select>


            <Select
              value={filters.Customer_Postal_Code}
              onChange={(e) => handleFilterChange('Customer_Postal_Code', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Postal Codes</MenuItem>
              {[...new Set(taskData.map((task) => task['Customer Postal Code']))].map((code, index) => (
                <MenuItem key={index} value={code}>
                  {code}
                </MenuItem>
              ))}
            </Select>


            <Select
              value={filters.program}
              onChange={(e) => handleFilterChange('program', e.target.value)}
              displayEmpty
              size="small"
              sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
            >
              <MenuItem value="">All Programs</MenuItem>
              {[...new Set(taskData.map((task) => task['Program']))].map((program, index) => (
                <MenuItem key={index} value={program}>
                  {program}
                </MenuItem>
              ))}
            </Select>
     
        
       
            <Select
  value={filters.dateRange}
  onChange={(e) => handleFilterChange('dateRange', e.target.value)}
  displayEmpty
  size="small"
  variant="outlined"
  sx={{ minWidth: '150px', background: '#f4f4f4', borderRadius: '4px' }}
>
  <MenuItem value="" disabled>
    Select Date Range
  </MenuItem>
  <MenuItem value="Today">Today</MenuItem>
  <MenuItem value="Past 7 Days">Past 7 Days</MenuItem>
  <MenuItem value="This Week">This Week</MenuItem>
  <MenuItem value="Last Month">Last Month</MenuItem>
  <MenuItem value="Custom Range">Custom Range</MenuItem>
</Select>

                        {/* Custom Date Range */}
                        {filters.dateRange === 'Custom Range' && (
              <>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.customStartDate}
                  onChange={(e) => handleFilterChange('customStartDate', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ minWidth: '180px' }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.customEndDate}
                  onChange={(e) => handleFilterChange('customEndDate', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ minWidth: '180px' }}
                />
              </>
            )}

          
      
                {/* Color Filters as Boxes */}
           
                <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              {/* Above Threshold */}
              <MenuItem value="">Color Maping :-</MenuItem>
 
              <Tooltip title="Above Threshold">
                <Box
                  onClick={() => handleFilterChange('color', 'Red')}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#EF9A9A',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: filters.color === 'Red' ? '0 0 6px 2px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                />
              </Tooltip>
 
              {/* Within Threshold */}
              <Tooltip title="Within Threshold">
                <Box
                  onClick={() => handleFilterChange('color', 'Green')}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#A5D6A7',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: filters.color === 'Green' ? '0 0 6px 2px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                />
              </Tooltip>
 
              {/* Needs Attention */}
              <Tooltip title="Needs Attention">
                <Box
                  onClick={() => handleFilterChange('color', 'Yellow')}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: '#FFF176',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    boxShadow: filters.color === 'Yellow' ? '0 0 6px 2px rgba(0, 0, 0, 0.2)' : 'none',
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
 
          
          {filters.State_Location && (
  <Box
    sx={{
      mt: 2,
      mb: 3,
      backgroundColor: '#f9f9f9',
      p: 2,
      borderRadius: '8px',
      boxShadow: boxShadowStyle,
    }}
  >
    <Typography
      variant="h6"
      sx={{ fontFamily: headerFont, fontWeight: 'bold', color: primaryColor }}
    >
      Average ECT for {filters.State_Location}: {calculateStateLocationAverageECT()} Days
    </Typography>
  </Box>
)}


{filters.PUDO_ID && (
  <Box
    sx={{
      mt: 2,
      mb: 3,
      backgroundColor: '#f9f9f9',
      p: 2,
      borderRadius: '8px',
      boxShadow: boxShadowStyle,
    }}
  >
    <Typography
      variant="h6"
      sx={{ fontFamily: headerFont, fontWeight: 'bold', color: primaryColor }}
    >
      Average ECT for PUDO ID {filters.PUDO_ID}: {calculatePudoIdAverageECT()} Days
    </Typography>
  </Box>
)}



        
          {/* Table */}
          <TableContainer component={Paper} sx={{ background: '#fff', borderRadius: '8px', boxShadow: boxShadowStyle }}>
            <Table>
              <TableHead>
                <TableRow sx={{ background: primaryColor }}>
                  <TableCell sx={{ color: '#fff' }}>Expand</TableCell>
                  <TableCell sx={{ color: '#fff' }}>City</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Average ECT (Days)</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Program</TableCell>
                  <TableCell sx={{ color: '#fff' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                {paginatedCities.map((city, index) => {
                  const cityTasks = filteredTasksByCity[city];
                  const averageECTDays =
                    cityTasks.reduce((sum, task) => sum + (isNaN(task.ect) ? 0 : parseFloat(task.ect)), 0) /
                    cityTasks.length;
 
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>
                          <IconButton size="small" onClick={() => handleRowClick(city)}>
                            {openCity[city] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{city}</TableCell>
                        <TableCell>{averageECTDays.toFixed(2)}</TableCell>
                        <TableCell>{cityTasks[0]['Program'] || 'N/A'}</TableCell>
                        <TableCell>{/* Color Box */}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <Collapse in={openCity[city]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Typography variant="h6" gutterBottom component="div">
                                Tasks for {city}
                              </Typography>
                              <Table size="small" aria-label="tasks">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Task WO#</TableCell>
                                    <TableCell>MSN#</TableCell>
                                    <TableCell>part Number</TableCell>
                                    <TableCell>part Decs</TableCell>
                                    <TableCell>Part Shipped/Delivered Date</TableCell>
                                    <TableCell>Service Completion Date</TableCell>
                                    <TableCell>ECT (Days)</TableCell>
                                    <TableCell>JetKing ECT</TableCell>
                                    <TableCell>Program</TableCell>
                                    {/* <TableCell>Color</TableCell> */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cityTasks.map((task, taskIndex) => (
                                    <TableRow key={taskIndex} style={{ backgroundColor: getECTRowColor(parseFloat(task.ect)) }}>
                                      <TableCell>{task['WO#']}</TableCell>
                                      <TableCell>{task['MSN#']}</TableCell>
                                      <TableCell>{task['part Number']}</TableCell>
                                      <TableCell>{task['part Desc']}</TableCell>
                                      <TableCell>{formatDateTime(task['Part Shipped/Delivered Date'])}</TableCell>
                                      <TableCell>{formatDateTime(task['Service Completion Date'])}</TableCell>
                                      <TableCell>{task.ect} days</TableCell>
                                      <TableCell>{task.ECT} days</TableCell>

                                      {/* <TableCell>
                                        {calculateECTInHours(task['Part Shipped/Delivered Date'], task['Service Completion Date'])} hours
                                      </TableCell> */}
                                      <TableCell>{task['Program'] || 'N/A'}</TableCell>
                                      {/* <TableCell>{task.colorCategory}</TableCell> */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
 
          <TablePagination
            component="div"
            count={Object.keys(filteredTasksByCity).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 25]}
          />
        </Box>
      </Box>
 
      <Footer />
    </Box>
  );
};
 
export default ECTDetails;