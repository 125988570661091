import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Toolbar,
  Button,
  Modal,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/apiService';
// Custom Components
import Header from '../components/Header';
import SideDrawer from '../components/Drawer';
import Footer from '../components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const RRRPage = () => {
  const [rrrData, setRrrData] = useState([]); // All data from Task collection
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [actualRepeatCount, setActualRepeatCount] = useState(0); // Actual Repeat WO count
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state for sidebar toggle
  const [historyModalOpen, setHistoryModalOpen] = useState(false); // Modal for WO history
  const [workOrderHistory, setWorkOrderHistory] = useState([]); // Old Work Order History

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const openHistoryModal = (woNumber, msn, partNumber) => {
    // Filter old work order history for the selected WO
    const history = rrrData.filter(
      (task) =>
        task['MSN#'] === msn &&
        task['part Number'] === partNumber &&
        task['WO#'] !== woNumber // Exclude the current WO
    );
    setWorkOrderHistory(history);
    setHistoryModalOpen(true);
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
    setWorkOrderHistory([]);
  };

  useEffect(() => {
    const fetchRRRData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/tasks`);
        const data = response.data;

        // Filter for Actual Repeat Work Orders
        const repeatData = data.filter((task, index, arr) => {
          // Ensure WO#, MSN#, and part Number are present and valid
          if (!task['WO#'] || !task['MSN#'] || !task['part Number']) return false;

          return arr.some(
            (otherTask) =>
              task['MSN#'] === otherTask['MSN#'] &&
              task['part Number'] === otherTask['part Number'] &&
              task['WO#'] !== otherTask['WO#']
          );
        });

        setRrrData(repeatData);
        setFilteredData(repeatData); // Show only Actual Repeat WO
        setActualRepeatCount(repeatData.length); // Update Actual Repeat WO count
      } catch (error) {
        console.error('Error fetching RRR data:', error);
      }
    };

    fetchRRRData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Define theme
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#FF4081',
      },
      background: {
        default: '#f4f6f8',
        paper: '#ffffff',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h4: {
        fontWeight: 600,
        fontSize: '2.25rem',
      },
      h6: {
        fontWeight: 500,
        fontSize: '1rem',
      },
      body1: {
        fontSize: '1rem',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            padding: '8px 24px',
            textTransform: 'none',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#1565c0',
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header handleDrawerToggle={handleDrawerToggle} title="RRR Details" />

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              backgroundColor: '#f4f6f8',
              paddingTop: '64px',
              borderRadius: '8px',
            }}
          >
            <Toolbar />

            <Typography variant="h4" gutterBottom>
              RRR Details
            </Typography>

            {/* RRR Count Display */}
            <Typography variant="h6" sx={{ mb: 3 }}>
              Actual Repeat Work Orders: {actualRepeatCount}
            </Typography>

            {/* Table */}
            {filteredData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Case Number</TableCell>
                      <TableCell>WO Number</TableCell>
                      <TableCell>MSN#</TableCell>
                      <TableCell>Service Completion Date</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((task, index) => (
                      <TableRow key={index}>
                        <TableCell>{task['Case#']}</TableCell>
                        <TableCell>{task['WO#']}</TableCell>
                        <TableCell>{task['MSN#']}</TableCell>
                        <TableCell>{new Date(task['Service Completion Date']).toLocaleString()}</TableCell>
                        <TableCell>{task['part Number']}</TableCell>
                        <TableCell>{task['Customer Name']}</TableCell>
                        <TableCell>{task['Status']}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() =>
                              openHistoryModal(task['WO#'], task['MSN#'], task['part Number'])
                            }
                          >
                            View History
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {/* Pagination */}
                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </TableContainer>
            ) : (
              <Typography sx={{ color: '#555' }}>
                No Actual Repeat Work Orders available.
              </Typography>
            )}
          </Box>
        </Box>

        {/* Modal for Work Order History */}
        <Modal open={historyModalOpen} onClose={closeHistoryModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Work Order History
            </Typography>
            {workOrderHistory.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>WO Number</TableCell>
                      <TableCell>Service Completion Date</TableCell>
                      <TableCell>MSN#</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workOrderHistory.map((task, index) => (
                      <TableRow key={index}>
                        <TableCell>{task['WO#']}</TableCell>
                        <TableCell>{new Date(task['Service Completion Date']).toLocaleString()}</TableCell>
                        <TableCell>{task['MSN#']}</TableCell>
                        <TableCell>{task['part Number']}</TableCell>
                        <TableCell>{task['Customer Name']}</TableCell>
                        <TableCell>{task['Status']}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No history available for this Work Order.</Typography>
            )}
          </Box>
        </Modal>

        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default RRRPage;
