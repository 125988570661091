const postalCodes = {
    "122002": { lat: 28.4650, lng: 77.0611 }, // Gurugram, Haryana
    "110053": { lat: 28.6075, lng: 77.2154 }, // Delhi
    "516269": { lat: 14.7900, lng: 78.8663 }, // Kadapa, Andhra Pradesh
    "400050": { lat: 19.0594, lng: 72.8495 }, // Mumbai, Maharashtra
    "411062": { lat: 18.5133, lng: 73.8729 }, // Pune, Maharashtra
    "523225": { lat: 15.8904, lng: 78.4813 }, // Kurnool, Andhra Pradesh
    "400070": { lat: 19.0635, lng: 72.8737 }, // Mumbai, Maharashtra
    "110063": { lat: 28.5955, lng: 77.1476 }, // Delhi
    "400076": { lat: 19.0845, lng: 72.8602 }, // Mumbai, Maharashtra
    "572106": { lat: 14.8980, lng: 75.5742 }, // Davangere, Karnataka
    "500082": { lat: 17.4394, lng: 78.4238 }, // Hyderabad, Telangana
    "201309": { lat: 28.5562, lng: 77.3885 }, // Ghaziabad, Uttar Pradesh
    "505301": { lat: 17.7485, lng: 78.3106 }, // Adilabad, Telangana
    "500016": { lat: 17.3980, lng: 78.4819 }, // Hyderabad, Telangana
    "560087": { lat: 12.9676, lng: 77.7502 }, // Bangalore, Karnataka
    "600041": { lat: 13.0385, lng: 80.2245 }, // Chennai, Tamil Nadu
    "110019": { lat: 28.5844, lng: 77.2250 }, // Delhi
    "122022": { lat: 28.4478, lng: 77.0328 }, // Gurugram, Haryana
    "560092": { lat: 12.9744, lng: 77.7050 }, // Bangalore, Karnataka
    "600113": { lat: 13.0474, lng: 80.2270 }, // Chennai, Tamil Nadu
    "400051": { lat: 19.0593, lng: 72.8423 }, // Mumbai, Maharashtra
    "400099": { lat: 19.0650, lng: 72.8688 }, // Mumbai, Maharashtra
    "560093": { lat: 12.9383, lng: 77.6240 }, // Bangalore, Karnataka
    "382350": { lat: 23.0694, lng: 72.5521 }, // Ahmedabad, Gujarat
    "560066": { lat: 12.9383, lng: 77.6240 }, // Bangalore, Karnataka
    "530026": { lat: 17.3956, lng: 78.4338 }, // Hyderabad, Telangana
    "562125": { lat: 13.0674, lng: 77.7102 }, // Bangalore, Karnataka
    "600034": { lat: 13.0624, lng: 80.2760 }, // Chennai, Tamil Nadu
    "411033": { lat: 18.5740, lng: 73.9203 }, // Pune, Maharashtra
    "600095": { lat: 13.0654, lng: 80.2608 }, // Chennai, Tamil Nadu
    "411027": { lat: 18.5430, lng: 73.8617 }, // Pune, Maharashtra
    "560064": { lat: 12.9668, lng: 77.6121 }, // Bangalore, Karnataka
    "590006": { lat: 15.8500, lng: 74.4580 }, // Belgaum, Karnataka
    "600001": { lat: 13.0878, lng: 80.2785 }, // Chennai, Tamil Nadu
    "500003": { lat: 17.3987, lng: 78.4765 }, // Hyderabad, Telangana
    "400072": { lat: 19.0630, lng: 72.8775 }, // Mumbai, Maharashtra
    "110051": { lat: 28.6142, lng: 77.2135 }, // Delhi
    "412101": { lat: 18.5305, lng: 73.7651 }, // Pune, Maharashtra
    "122006": { lat: 28.4580, lng: 77.0956 }, // Gurugram, Haryana
    "500010": { lat: 17.3976, lng: 78.4754 }, // Hyderabad, Telangana
    "502319": { lat: 17.3862, lng: 78.5585 }, // Hyderabad, Telangana
    "122017": { lat: 28.4089, lng: 77.3178 }, // Gurugram, Haryana (added)
    "400052": { lat: 19.0665, lng: 72.8311 }, // Mumbai, Maharashtra (added)
};
 
// Export the postalCodes object for use in your application
export default postalCodes;
 
 