import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stack,
  Grid
} from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import postalCodes from './postalCodes'; // Adjust this path if needed
 
// Fix default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
 
const TicketDetails = ({ ticket, onClose }) => {
  const [customerLocation, setCustomerLocation] = useState(null);
  const [fseLocation, setFseLocation] = useState(null);
  const [fseAddress, setFseAddress] = useState('');
  const [initialLocation, setInitialLocation] = useState(null);
  const [error, setError] = useState(null);
  const [initialAddress, setInitialAddress] = useState('');
 
  useEffect(() => {
    // Lookup the initial customer location using postal code
    const postalCode = ticket["Customer Postal Code"];
    if (postalCodes[postalCode]) {
      setCustomerLocation(postalCodes[postalCode]);
      setError(null);
    } else {
      setError("Postal code not found");
      console.error("Postal code not found:", postalCode);
    }
 
    // Set FSE location if available
    const currentLocation = ticket["Current Location"];
    if (currentLocation?.latitude && currentLocation?.longitude) {
      setFseLocation([currentLocation.latitude, currentLocation.longitude]);
      setFseAddress(currentLocation.address || "Address not available");
    }
 
    // Set Initial Location from Task collection
    const initialLoc = ticket["initial Location"];
    if (initialLoc?.latitude && initialLoc?.longitude) {
      setInitialLocation([initialLoc.latitude, initialLoc.longitude]);
      setInitialAddress(initialLoc.address || "Address not available");
    }
  }, [ticket]);
 
  // Create custom marker icon with static size and fixed color for FSE
  const markerIcon = new L.Icon({
    iconUrl: 'https://jetking.techjardemo.in/static/media/logo1.c347f8c5deb243b85451.png',  // Use a static marker for FSE
    iconSize: [40, 64],  // Larger size for FSE
    iconAnchor: [20, 64], // Icon anchor point (adjusted for larger size)
    popupAnchor: [1, -60],  // Popup anchor point adjusted for larger icon
  });
 
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Ticket Details</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {/* Ticket Information */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Ticket Information</Typography>
            </Grid>
 
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">Customer Name:</Typography>
              <Typography variant="body2">{ticket["Customer Name"]}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">Case#:</Typography>
              <Typography variant="body2">{ticket["Case#"]}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">WO#:</Typography>
              <Typography variant="body2">{ticket["WO#"]}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">MSN#:</Typography>
              <Typography variant="body2">{ticket["MSN#"]}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">Contact:</Typography>
              <Typography variant="body2">{ticket["Customer Mobile Number"]}</Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="subtitle2" fontWeight="bold">Updated By:</Typography>
              <Typography variant="body2">{ticket["Updated By"]}</Typography>
            </Grid>
          </Grid>
 
          {/* Location Details */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Location Details</Typography>
            </Grid>
 
            {/* Initial Location */}
            {initialLocation && (
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">Initial Location:</Typography>
                <Typography variant="body2">Latitude: {initialLocation[0]}</Typography>
                <Typography variant="body2">Longitude: {initialLocation[1]}</Typography>
                <Typography variant="body2">Address: {initialAddress}</Typography>
              </Grid>
            )}
 
            {/* FSE Location */}
            {fseLocation && (
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" fontWeight="bold">FSE Current Location:</Typography>
                <Typography variant="body2">Latitude: {fseLocation[0]}</Typography>
                <Typography variant="body2">Longitude: {fseLocation[1]}</Typography>
                <Typography variant="body2">Address: {fseAddress}</Typography>
              </Grid>
            )}
 
            {/* Map with Customer, FSE, and Initial Locations */}
            {customerLocation && (fseLocation || initialLocation) && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight="bold">Locations on Map:</Typography>
                <MapContainer center={customerLocation} zoom={15} style={{ height: '250px', width: '100%' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Switch to OpenStreetMap for better reliability
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {/* Draw a line between customer, FSE, and initial locations */}
                  <Polyline
                    positions={[
                      customerLocation,
                      ...(initialLocation ? [initialLocation] : []),
                      ...(fseLocation ? [fseLocation] : []),
                    ]}
                    color="blue"
                  />
                  <Marker position={customerLocation}>
                    <Popup>
                      Customer Latitude: {customerLocation[0]}<br />
                      Customer Longitude: {customerLocation[1]}<br />
                      Address: {ticket["Customer Address"] || "Address not available"}<br />
                    </Popup>
                  </Marker>
                  {initialLocation && (
                    <Marker position={initialLocation}>
                      <Popup>
                        Initial Latitude: {initialLocation[0]}<br />
                        Initial Longitude: {initialLocation[1]}<br />
                        Address: {initialAddress}<br />
                      </Popup>
                    </Marker>
                  )}
                  {fseLocation && (
                    <Marker position={fseLocation} icon={markerIcon}>
                      <Popup>
                        FSE Latitude: {fseLocation[0]}<br />
                        FSE Longitude: {fseLocation[1]}<br />
                        Address: {fseAddress}<br />
                      </Popup>
                    </Marker>
                  )}
                </MapContainer>
              </Grid>
            )}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};
 
export default TicketDetails;