import React ,{ useState }from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Tooltip, Box, Typography, Button,Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BugReportIcon from '@mui/icons-material/BugReport';  // Problems Icon
import LockIcon from '@mui/icons-material/Lock';  // Changes Icon
import BoltIcon from '@mui/icons-material/Bolt';  // Releases Icon
import TaskIcon from '@mui/icons-material/Task';  // Tasks Icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';  // More Icon
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';  // Logout Icon
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Calendar Icon
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'; // Tickets Icon
import { useNavigate } from 'react-router-dom';
import logo from '../assets/white.png';  // Adjust this path based on your project structure
import EditIcon from '@mui/icons-material/Edit';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const drawerWidth = 240;
const collapsedWidth = 60;


const SideDrawer = ({ open, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  const onMenuClick = (menuItem) => {
    switch (menuItem) {
      case 'Dashboard':
        navigate('/admin');
        break;
      case 'Assign Task Order':
        navigate('/assign-task-order-table');
        break;
      case 'Assigned Field Engineer':
        navigate('/assign-field-engineer');
        break;
      case 'Calendar':
        navigate('/resource-utilization');
        break;
      case 'Tickets':
        navigate('/tickets');
        break;
      case 'Escalation':
        navigate('/escalation');
        break;
      case 'FSEUsers':
        navigate('/fseusers');
        break;
      case 'Logout':
        setLogoutDialogOpen(true);
                break;
    
      default:
        break;
    }
    
  };


  const confirmLogout = () => {
    setLoggingOut(true);
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  };
 
  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };
 

  return (
    <>
    <Drawer
      anchor="left"
      open={open}
      onClose={handleDrawerToggle}
      variant="permanent"
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
          background: '#BC3232',
          color: '#fff',
          transition: 'width 0.3s ease',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflow: 'hidden', // Prevent horizontal scroll bar
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: open ? 'flex-end' : 'center', alignItems: 'center', padding: '8px' }}>
        {open && (
          <IconButton onClick={handleDrawerToggle} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: open ? 'flex-start' : 'center',
          alignItems: 'center',
          padding: '16px',
          cursor: 'pointer',
        }}
        onClick={handleDrawerToggle}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: open ? '200px' : '30px',
            marginRight: open ? '5px' : '0',
            transition: 'width 0.3s ease',
          }}
        />
        {open && (
          <Typography variant="h6" noWrap sx={{ fontWeight: 'bold', color: '#fff' }}>
            {/* Add text here if needed */}
          </Typography>
        )}
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <List>
          <Tooltip title="Dashboard" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Dashboard')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <DashboardIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Dashboard" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Assign Task Order" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Assign Task Order')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <AssignmentIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Assign Task Order" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Assigned Field Engineer" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Assigned Field Engineer')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <PersonIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Assigned Field Engineer" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Calendar" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Calendar')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <CalendarTodayIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Calendar" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Tickets" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Tickets')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Tickets" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Escalation" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('Escalation')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <WarningAmberIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Escalation" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>

          <Tooltip title="Users Setting" placement="right" arrow disableHoverListener={open}>
            <ListItem button onClick={() => onMenuClick('FSEUsers')}>
              <ListItemIcon sx={{ color: '#fff', minWidth: open ? '40px' : 'auto' }}>
                <ManageAccountsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="FSEUsers" sx={{ color: '#fff' }} />}
            </ListItem>
          </Tooltip>
        </List>
      </Box>

      <Box >
        <Button
          color="inherit"
          startIcon={<LogoutIcon />}
          onClick={() => onMenuClick('Logout')}
          sx={{mb:4}}
        >
          {open && 'Logout'}
        </Button>
      </Box>
    </Drawer>
  <Dialog open={logoutDialogOpen} onClose={closeLogoutDialog}>
  <DialogTitle>Confirm Logout</DialogTitle>
  <DialogContent>
    <DialogContentText sx={{mb:'3', mt:0 }}>Are you sure you want to log out?</DialogContentText>
  </DialogContent>
  <DialogActions><Button onClick={closeLogoutDialog} color="secondary">Cancel</Button>
    <Button onClick={confirmLogout} color="primary" autoFocus>Logout
  </Button>
  </DialogActions>
</Dialog>

{loggingOut && (
  <Box sx={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1301,}}>
    <CircularProgress size={50} sx={{ color: '#fff' }} />
  </Box>
)}
</>
);
};

export default SideDrawer;
