import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// Display detailed data for the clicked card
const CardDetailsPage = () => {
  const location = useLocation();
  const { taskData, filterKey } = location.state || {};
  
  // Filter tasks based on the filter key
  const getFilteredTasks = () => {
    switch (filterKey) {
      case 'Overdue':
        return taskData.filter(task => task["Part Status"] === 'Shipped' || task["Part Status"] === 'Labor Only');
      case 'Open':
        return taskData.filter(task => task.Status === 'Accepted');
      case 'Hold':
        return taskData.filter(task => task["WO Track"] === 'Paused');
      case 'Complete':
        return taskData.filter(task => task["WO Track"] === 'Completed');
      case 'ECT':
        return taskData.filter(task => task["ECT"]);  // Filter based on ECT
      default:
        return taskData;
    }
  };

  const filteredTasks = getFilteredTasks();

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {filterKey} Tasks
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Case Number</TableCell>
              <TableCell>WO Number</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>WO Track</TableCell>
              <TableCell>Part Status</TableCell>
              <TableCell>ECT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks.map((task, index) => (
              <TableRow key={index}>
                <TableCell>{task["Case#"]}</TableCell>
                <TableCell>{task["WO#"]}</TableCell>
                <TableCell>{task["Customer Name"]}</TableCell>
                <TableCell>{task.Status}</TableCell>
                <TableCell>{task["WO Track"]}</TableCell>
                <TableCell>{task["Part Status"]}</TableCell>
                <TableCell>{task["ECT"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CardDetailsPage;
