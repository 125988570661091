import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const LandingPage = () => {
  const navigate = useNavigate(); // Hook to handle navigation

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to right, #8360c3, #2ebf91)',
        color: '#fff',
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
        Welcome to Jetking's App
      </Typography>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Your Ultimate ITSM Solution
      </Typography>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#f5f5f5',
            color: '#2ebf91',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            padding: '0.5rem 2rem',
            borderRadius: '30px',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#2ebf91',
              color: '#fff',
              transform: 'translateY(-5px)',
            },
          }}
          onClick={() => navigate('/register')} // Navigate to Register page on click
        >
          Register
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#f5f5f5',
            color: '#8360c3',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            padding: '0.5rem 2rem',
            borderRadius: '30px',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#8360c3',
              color: '#fff',
              transform: 'translateY(-5px)',
            },
          }}
          onClick={() => navigate('/login')} // Navigate to Login page on click
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default LandingPage;
