import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import Header from './Header'; // Ensure these components are correctly imported
import Footer from './Footer';
import SideDrawer from './Drawer';
import { API_BASE_URL } from '../services/apiService';

const FSEUsers = () => {
  const [fieldEngineers, setFieldEngineers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);


  const fetchFieldEngineers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/field-engineers`);
      setFieldEngineers(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch field engineers');
      console.error('Error fetching field engineers:', err);
    } finally {
      setLoading(false);
    }
  };

  const toggleBlockStatus = async (engineerId) => {
    try {
      // Call the API to toggle the block/unblock status
      const response = await axios.put(`${API_BASE_URL}/field-engineer/${engineerId}/block`);
  
      if (response.data.success) {
        // Update state only after successful API response
        setFieldEngineers((prevEngineers) =>
          prevEngineers.map((engineer) =>
            engineer._id === engineerId
              ? { ...engineer, isBlocked: !engineer.isBlocked }
              : engineer
          )
        );
      } else {
        console.error('API responded with an error:', response.data.msg);
        setError(response.data.msg || 'Failed to toggle block status.');
      }
    } catch (error) {
      console.error('Error toggling block status:', error);
      setError('Failed to update block status. Please try again.');
    }
  };
  
  
  useEffect(() => {
    fetchFieldEngineers();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: drawerOpen ? '240px' : '0',
          flexShrink: 0,
          transition: 'width 0.3s ease',
          backgroundColor: '#fff',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1100,
          overflowY: 'auto',
        }}
      >
        <SideDrawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      </Box>

      {/* Main Content Area */}
      <Box
        sx={{
          marginLeft: drawerOpen ? '180px' : '0',
          transition: 'margin-left 0.3s ease',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '64px',
            backgroundColor: '#fff',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1200,
          }}
        >
          <Header handleDrawerToggle={handleDrawerToggle} title="Admin Dashboard" />
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            padding: '24px',
            backgroundColor: '#f9f9f9',
            marginTop: '60px',
            marginLeft:7,
          }}
        >
          <Box
                  sx={{
                    backgroundColor: 'secondary.main', // Use theme color for background
                    padding: '10px 20px', 
                    borderRadius: '8px', 
                    marginBottom: '20px', 
                    marginBottom:'30px'
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: '400', 
                      color: '#fff', 
                      textTransform: 'uppercase', 
                      letterSpacing: '6px',
                      textAlign:'center',
                    }}
                  >
                    Field Engineers Data
                  </Typography>
                </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography variant="body1" color="error" align="center" sx={{ fontSize: '14px' }}>
              Error: {error}
            </Typography>
          ) : (
            <TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
              <Table sx={{ minWidth: 700 }} aria-label="field engineers table">
                <TableHead sx={{ backgroundColor: '#1976d2' }}>
                  <TableRow>
                    {['Username', 'Email', 'Contact', 'First Name', 'Last Name', 'State', 'City', 'Actions'].map(
                      (header) => (
                        <TableCell
                          key={header}
                          sx={{
                            color: 'white',
                            fontWeight: '400',
                            textAlign: 'center',
                          }}
                        >
                          {header}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fieldEngineers.map((engineer) => (
                    <TableRow key={engineer._id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.username}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.email}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.contactNumber}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.firstname}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.surename}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.state}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{engineer.city}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                      <Button
                          variant="contained"
                          sx={{
                            backgroundColor: engineer.isBlocked ? '#FF4D4D' : '#4CAF50',
                            '&:hover': {
                              backgroundColor: engineer.isBlocked ? '#FF3333' : '#45A049',
                            },
                          }}
                          size="small"
                          onClick={() => toggleBlockStatus(engineer._id)}
                        >
                          {engineer.isBlocked ? 'Unblock' : 'Block'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        {/* Footer */}
        <Box
          sx={{
            flexShrink: 0,
            height: '42px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(5px)',
          }}
        >
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default FSEUsers;
