import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Grid, IconButton, Button } from '@mui/material';
import SideDrawer from '../components/Drawer'; // Update the path if necessary
import Header from '../components/Header'; // Update the path if necessary
import Footer from '../components/Footer'; // Update the path if necessary
import AssessmentIcon from '@mui/icons-material/Assessment'; // SLA Icon
import ScheduleIcon from '@mui/icons-material/Schedule'; // ECT Icon
import { useNavigate } from 'react-router-dom';

const EscalationPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate(); // Use navigate for routing

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleSLAWiseEscalationClick = () => {
    navigate('/sla-management'); // Navigate to the SLA Management page
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#f9fafc' }}>
      {/* Header */}
      <Header handleDrawerToggle={toggleDrawer} />

      {/* Main Layout */}
      <Box sx={{ display: 'flex', flex: 1 }}>
        {/* Side Drawer */}
        <SideDrawer open={drawerOpen} handleDrawerToggle={toggleDrawer} />

        {/* Main Content Area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 4,
            pt: 10, // Add padding top to prevent content from overlapping with the header
            backgroundColor: '#f4f6f8', // Light gray background for contrast
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#333', mb: 3 }}>
            Escalation Management
          </Typography>

          <Typography variant="body1" sx={{ color: '#555', mb: 4 }}>
            Welcome to the Escalation Management page. You can manage and view all escalations here. Use the
            cards below to explore SLA-wise and ECT-wise escalations.
          </Typography>

          {/* Card Layout */}
          <Grid container spacing={3}>
            {/* SLA-wise Escalation */}
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  background: 'linear-gradient(135deg, #1d3557, #457b9d)',
                  color: '#fff',
                  borderRadius: '16px',
                  boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.3)',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out',
                  },
                }}
                onClick={handleSLAWiseEscalationClick}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      sx={{
                        color: '#fff',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                        mr: 2,
                      }}
                    >
                      <AssessmentIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      SLA-wise Escalation
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 2, opacity: 0.9 }}>
                    Track escalations by SLA to ensure timely resolutions and minimize delays.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 3,
                      borderColor: '#fff',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    }}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* ECT-wise Escalation */}
            <Grid item xs={12} sm={6}>
              <Card
                sx={{
                  background: 'linear-gradient(135deg, #6a0572, #b91372)',
                  color: '#fff',
                  borderRadius: '16px',
                  boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.3)',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out',
                  },
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                      sx={{
                        color: '#fff',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                        mr: 2,
                      }}
                    >
                      <ScheduleIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                      ECT-wise Escalation
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 2, opacity: 0.9 }}>
                    Monitor escalations based on ECT to meet deadlines.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 3,
                      borderColor: '#fff',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    }}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default EscalationPage;
